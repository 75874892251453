<template>
  <div>
    <p class="text--disabled mt-6 mb-2">{{ t("domain_table.detailed_row_title") }} {{ domain }}</p>
    <v-data-table
      class="elevation-1 mb-4"
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="total"
      :items-per-page="options.itemsPerPage"
      :loading="loading"
      :footer-props="footerProps"
      item-key="referrer"
      @update:options="onOptionsUpdate"
    >
    </v-data-table>
  </div>
</template>
<script>
import dataTable from "@/views/mixins/dataTable";
/**
 * Таблица с URL конкретного домена referrers
 */
export default {
  mixins: [dataTable],
  props: {
    domain: { type: String, default: null },
    appType: { type: String, default: null },
    datesFilter: { type: Object, default: new Object() }
  },
  data() {
    return {
      options: {
        itemsPerPage: 25,
        sortBy: ["hits"],
        sortDesc: [true]
      }
    };
  },

  computed: {
    headers() {
      return [
        {
          text: this.t("url_table.fields.url"),
          value: "referrer",
          sortable: true
        },
        {
          text: this.t("domain_table.fields.hits"),
          value: "hits",
          sortable: true
        },
        {
          text: this.t("domain_table.fields.downloads"),
          value: "downloads",
          sortable: true
        },
        {
          text: this.t("domain_table.fields.unique_downloads"),
          value: "unique_downloads",
          sortable: true
        }
      ];
    }
  },

  watch: {
    /**
     * Если поисковая строка изменилась
     */
    domain() {
      this.loadData();
    },

    /**
     * Если изменился apptype
     */
    appType() {
      this.loadData();
    }
  },

  methods: {
    /**
     * Получение перевода текущей группы
     */
    t(key, params) {
      return this.$t("pages.statistics.sources." + key, params);
    },

    /**
     * Формирование параметров для запроса данных
     */
    getLoadingParams() {
      let data = {
        domain: this.domain,
        app: this.appType
      };
      return Object.assign(this.getBaseParams(), data, this.datesFilter);
    },

    /**
     * Загрузка данных с сервера
     */
    loadData() {
      this.loading = true;
      this.$store
        .dispatch("statistics/loadSourcesDetailsData", this.getLoadingParams())
        .then(this.processDataLoadResponse)
        .catch(this.catchDataLoadResponse);
    }
  }
};
</script>
