<template>
  <v-row>
    <v-col cols="12">
      <stats-page-header />
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title class="justify-space-between">
          <div class="card-title ma-0 ">
            {{ t("main_block_title") }}
          </div>
          <v-spacer />

          <v-menu
            ref="menu"
            v-model="showDatePicker"
            :close-on-content-click="false"
            :return-value.sync="dates"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                :label="t('dates_placeholder')"
                prepend-icon="mdi-calendar"
                readonly
                single-line
                hide-details
                class="me-3 ca-filter-input"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="dates" range no-title scrollable :min="minDate" :max="maxDate">
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="showDatePicker = false">
                {{ $t("common.cancel") }}
              </v-btn>
              <v-btn text color="primary" @click="applyDateFilter">
                {{ $t("common.ok") }}
              </v-btn>
            </v-date-picker>
          </v-menu>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="t('search_placeholder')"
            single-line
            hide-details
            class="ca-filter-input"
          />
        </v-card-title>

        <v-data-table
          class="elevation-1"
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="total"
          :items-per-page="options.itemsPerPage"
          :loading="loading"
          :footer-props="footerProps"
          show-expand
          :single-expand="true"
          :expanded.sync="expanded"
          item-key="referrer"
          @update:options="onOptionsUpdate"
        >
          <template #expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <referrers-details-table
                :app-type="appType"
                :domain="item.referrer"
                :dates-filter="datesFilter"
              ></referrers-details-table>
            </td>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import ReferrersDetailsTable from "@/views/pages/statistics/sources/ReferrersDetailsTable";
import { mapGetters } from "vuex";
import dataTable from "@/views/mixins/dataTable";
import StatsPageHeader from "../StatsPageHeader";

/**
 * Таблица с доменами источников
 */
export default {
  components: { StatsPageHeader, ReferrersDetailsTable },
  metaInfo() {
    return {
      title: this.$t("titles.stats_sources")
    };
  },
  mixins: [dataTable],
  data() {
    return {
      options: {
        itemsPerPage: 25,
        sortBy: ["hits"],
        sortDesc: [true]
      },
      search: null,
      searchTimer: null,
      expanded: [],
      dates: null,
      showDatePicker: false,
      minDate: null,
      maxDate: null
    };
  },

  computed: {
    ...mapGetters("app", ["appDomain", "appTitle"]),

    /**
     * Apptype статистики
     */
    appType() {
      return this.$route.params.app;
    },

    /**
     * Колонки таблицы
     */
    headers() {
      return [
        {
          text: this.t("domain_table.fields.domain"),
          value: "referrer",
          sortable: true
        },
        {
          text: this.t("domain_table.fields.hits"),
          value: "hits",
          sortable: true
        },
        {
          text: this.t("domain_table.fields.downloads"),
          value: "downloads",
          sortable: true
        },
        {
          text: this.t("domain_table.fields.unique_downloads"),
          value: "unique_downloads",
          sortable: true
        },
        {
          text: this.t("domain_table.fields.purchases"),
          value: "purchases",
          sortable: true
        },
        { text: "", value: "data-table-expand" }
      ];
    },

    /**
     * Имя текущей секции
     */
    currentRouteName() {
      return this.$route.name;
    },

    /**
     * Данные для хлебных крошек
     */
    routePath() {
      return [
        {
          text: this.$t("app.sections.dashboard"),
          disabled: false,
          href: "/"
        },
        {
          text: this.appTitle(this.appType),
          disabled: false,
          href: "/"
        },
        {
          text: this.$t("app.sections.statistics"),
          disabled: true,
          href: "/"
        },
        {
          text: this.$t("app.sections.stats.sources"),
          disabled: true,
          href: "/"
        }
      ];
    },

    /**
     * Строка для отображения выбранных дат
     */
    dateRangeText() {
      return this.dates
        ? Array.from(this.dates)
            .sort()
            .join(" - ")
        : "";
    },

    /**
     * Объект с условиям фильтрации по датам
     */
    datesFilter() {
      let data = {};
      if (this.dates) {
        const dates = Array.from(this.dates).sort();
        if (dates[0]) {
          data.from = dates[0];
        }
        if (this.dates[1]) {
          data.to = dates[1];
        }
      }
      return data;
    }
  },

  watch: {
    /**
     * Если поисковая строка изменилась
     */
    search() {
      clearTimeout(this.searchTimer);
      // Задержка, чтобы не делать запрос после каждой буквы при наборе длинного условия
      this.searchTimer = setTimeout(() => {
        this.expanded = [];
        this.loadData();
      }, 2000);
    },

    /**
     * Если изменился apptype
     */
    appType() {
      this.expanded = [];
      this.loadData();
    }
  },

  methods: {
    /**
     * Получение перевода текущей группы
     */
    t(key, params) {
      return this.$t("pages.statistics.sources." + key, params);
    },

    /**
     * Формирование параметров для запроса данных
     */
    getLoadingParams() {
      let data = {
        app: this.appType,
        search: this.search
      };
      return Object.assign(this.getBaseParams(), data, this.datesFilter);
    },

    /**
     * Загрузка данных с сервера
     */
    loadData() {
      this.loading = true;
      this.$store
        .dispatch("statistics/loadSourcesBaseData", this.getLoadingParams())
        .then(this.processBaseDataLoadResponse)
        .catch(this.catchDataLoadResponse);
    },

    /**
     * Обработка запроса базовых данных
     * @param response
     */
    processBaseDataLoadResponse(response) {
      this.processDataLoadResponse(response);
      this.minDate = response.data.min;
      this.maxDate = response.data.max;
      if (this.dates == null) {
        this.dates = [this.minDate, this.maxDate];
      }
    },

    /**
     * Применение фильтра с датами
     */
    applyDateFilter() {
      this.$refs.menu.save(this.dates);
      this.expanded = [];
      this.loadData();
    }
  }
};
</script>

<style lang="scss" scoped>
.ca-filter-input {
  max-width: 15em;
}
</style>
